var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"menu-logged"}},[_c('v-list',{staticClass:"list-menu"},[_c('v-list-item',{staticClass:"height-menu"},[_c('v-list-item-title',{staticClass:"title-menu-logged"},[(_vm.$vuetify.breakpoint.width < 1115)?_c('h1',[_vm._v("Olá, "+_vm._s(_vm.user.primeiro_nome))]):_vm._e(),(_vm.user.categoria.titulo)?_c('p',[_vm._v("Categoria: "+_vm._s(_vm.user.categoria.titulo == null ? '': _vm.user.categoria.titulo))]):_vm._e(),_c('h2',[_vm._v("Saldo: "+_vm._s(_vm.user.saldo))]),(_vm.establishment.configuracoes.exibir_pontuacao_acumulada_ranking)?_c('h1',[_vm._v(_vm._s(_vm.establishment.moeda)+" ganhos: "+_vm._s(Intl.NumberFormat("de-DE").format(_vm.singleRanking.rankingPersonInfo.pontuacao)))]):_vm._e(),_c('br')])],1),_vm._l((_vm.itemsMenuLogged),function(item,index){return _c('v-list-item',{key:index,staticClass:"height-menu",class:{
        'd-none':
          (!_vm.establishment.configuracoes.cliente_pode_indicar &&
          item.name.includes('Indique')) 
          || (!_vm.establishment.configuracoes.habilitar_codigo_pontuavel &&
          item.name.includes('Código Pontuavel')) 
          || (!_vm.establishment.configuracoes.mimoAtivo &&
          item.name.includes('Meus mimos'))
          || (!_vm.establishment.configuracoes.ranking &&
          item.name.includes('Ranking'))
          || ((!_vm.establishment.configuracoes.habilitar_doacao ||
          !_vm.establishment.contractedResources.includes('doar_pontos__')) &&
          item.name.includes('Transferir Pontos')),
      },attrs:{"link":""}},[_c('router-link',{attrs:{"to":item.to ? ("/" + (_vm.$route.params.slug) + (item.to)) : ''},nativeOn:{"click":function($event){return _vm.closeModal.apply(null, arguments)}}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.logoutUser(item.name)}}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }