import ForgotPassword from './forgot_password/ForgotPassword.vue'
import Regulation from '../../views/regulation/Regulation.vue'

import { validateForm, getSlugFromRoute } from "../../../assets/js/Utils.js"
import { mapActions, mapState } from "vuex"

export default {
    components: {
        ForgotPassword,
        Regulation
    },

    props: {
        customItems: Object
    },

    data: () => ({
        validateForm,
        showPassword: false,
        form: {},
        customItemsLogin: null
    }),

    created(){
        window.scrollTo(0,0)
        this.customItemsLogin = this.customItems.login
        if (this.$route.params.token){
            let rawEmail = Buffer.from(this.$route.params.token, 'base64').toString()
            this.directLogin(rawEmail)
        }
    },

    methods: {
        async directLogin(login){
            const response = await this.MODULOS.client.loginOnlyEmail(login);
            if (response.status === 200) {
                sessionStorage.setItem('user', JSON.stringify({ token: response.token, id: response.cliente }) )
                await this.actionGetUser(response.cliente)
                await this.actionGetBalanceCliente()
                this.$toasted.global.success({ msg: `Bem vindo ${this.user.primeiro_nome.split(' ')[0]}!` })
                this.$router.push({ path: `/${getSlugFromRoute(this.$route.path)}` }).catch(() => document.getElementById('app').scrollIntoView())
                return
            }
        },
        async login() {
            const resp = await this.MODULOS.client.login(this.form)
            if (resp.status === 200) {
                sessionStorage.setItem('user', JSON.stringify({ token: resp.token, id: resp.cliente }))
                await this.actionGetUser(resp.cliente)
                await this.actionGetBalanceCliente()
                this.$toasted.global.success({ msg: `Bem vindo ${this.user.primeiro_nome.split(' ')[0]}!` })
                this.$router.push({ path: `/${getSlugFromRoute(this.$route.path)}` }).catch(() => document.getElementById('app').scrollIntoView())
                location.reload();
                return
            }
            this.$toasted.global.error({ msg: 'Usuario ou senha inválidos' })
        },
 
        ...mapActions(['actionGetUser','actionGetBalanceCliente'])
    },

    computed: {
        ...mapState(['user','establishment'])
    }
}