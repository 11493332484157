import { get, httpDelete, post, put } from './Methods';

export const donuz = {
  configStore:{
    getConfigStore: () => get('loja/config'),
    postConfigStore: (body) => post('loja/config', {body}),
    putConfigStore: (body) => put('loja/config', {body})
  },  
  establishment: {
    getEstablishment: () => get('estabelishment'),
    getEstablishmentBlocked: (slug) => get(`estabelishment/blocked/${slug}`),
    getEstablishmentFields: (fields) => get(`estabelishment?fields=${fields}`),
    getBanners: () => get("estabelishments/banners", { isTokenClient: !!sessionStorage.getItem("user") }),
    getSettings: () => get('settings'),
    settingsApp: () => get('settings/check/app'),
    getSettingsMyData: () => get('settings/meus-dados'),
    getBranchs: () => get('estabelishment/branchs'),
    getCustomConfigs: () => get('custom/config'),
    postCustomConfigs: (body) => post('custom/config',{body}),
    loginAdmin: (body) => post('estabelishment/login',{body}),
    findBySlug:(body)=> post('estabelishments',{body}),
    getPermission: () => get('estabelishment/permission'),
    getContractedResources: () => get('recurso/contratados')
  },
  prize: {
    getAll: () => get("prizes", { isTokenClient: !!sessionStorage.getItem("user") }),
    getFilters: (body) => post('prizes', { body }),
    getCategories: () => get('prizes/categories'),
    getPrize: (idPrize) => get(`prize/${idPrize}`),
    getSubCategories: (idCategory) => get(`prizes/subcategories/${idCategory}`)
  },
  client: {
    register: (body) => post('client', { body }),
    update: (body) => put('client', { body, isTokenClient: true }),
    login: (body) => post('clients/login', { body }),
    loginOnlyEmail: (login) => post('clients/loginOnlyEmail', { body: {login} }),
    getClient: (idClient) => get(`client/id/${idClient}`, { isTokenClient: true }),
    getBalance: () => get(`client/balance`, { isTokenClient: true }),
    forgotPassword: (body) => post('clients/forgotPassword', { body }),
    emailInvite: (body) => post('emailInvite', { isTokenClient: true, body }),
    getClientByCode: (codigo_cliente) => get(`client/${codigo_cliente}`),
    getClientId: (id) => get(`public/client/id/${id}`),
    defaultDeliveryAddress: (customerId, body) => put(`clients/${customerId}/default-delivery-address`,
      { body, isTokenClient: true }),
  },
  redemption: {
    redemptionPrize: (body) => post('redeemptions', { body, isTokenClient: true }),
    getRedemption: (idRedemption) => get(`redeemption/${idRedemption}`),
    getAllRedemptions: () => get('redeemptions', { isTokenClient: true })
  },
  contact: {
    sendEmail: (body) => post('contact', { body }),
  },
  points: {
    getPoints: () => get('points', { isTokenClient: true }),
    getRanking: (page = 0) => get(`points/ranking?page=${page}`, { isTokenClient: !!sessionStorage.getItem("user") }),
    getSingleRanking: (idClient) => get(`points/ranking/${idClient}`, { isTokenClient: true }),
    transferPoints: (body) => post('points/donate', { body,isTokenClient: true }),
    getPointsWithFilters: (body) => post("points/filter", { body, isTokenClient: true }),
  },
  gifts: {
    getAllGifts: () => get('gifts', { isTokenClient: true })
  },
  config: {
    getConfig:() => get('loja/config')
  },
  loginToken: {
    login: (body) => post('loginToken', { body })
  },
  computableCode: {
    validate: (body) => post("computableCode",{body, isTokenClient: true})
  },
  deliveryAddresses: {
    get: (customerId) => get(`delivery-addresses/client/${customerId}`, { isTokenClient: true }),
    post: (customerId, body) => post(`delivery-addresses/client/${customerId}`, { body, isTokenClient: true }),
    put: (addressId, customerId, body) => put(`delivery-addresses/${addressId}/client/${customerId}`,
      { body, isTokenClient: true }),
    delete: (addressId, customerId) => httpDelete(`delivery-addresses/${addressId}/client/${customerId}`,
      { isTokenClient: true }),
  }
};