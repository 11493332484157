import { mapState } from 'vuex'

export default {
  props: {
    customItems: Object
  },

  data: () => ({
    loading: false,
    points: null,
    pointsOfPage: [],
    customItemsMyPoints: null,
    singleRanking: { rankingPersonInfo: { pontuacao: 0 } }
  }),

  async created() {
    window.scrollTo(0, 0)
    this.loading = true
    this.customItemsMyPoints = this.customItems.my_points
    if (this.user) {
      const resp = await this.MODULOS.points.getPoints()
      this.points = resp.pontos.sort((a, b) => new Date(a.date).getTime() > new Date(b.date).getTime() ? 1 : -1)
    }
    this.loading = false
    this.getPointsOnRanking()
  },

  computed: {
    ...mapState(["user", "establishment"])
  },


  methods: {
    getPointIcon(point) {
      switch (point.operacao) {
        case "D":
          return this.pointIsTransfer(point)
            ? window.ICONS.mdiCurrencyUsd
            : window.ICONS.mdiGiftOutline;
        case "E":
          return window.ICONS.mdiCurrencyUsdOff;
        case "C":
        default:
          return window.ICONS.mdiCurrencyUsd;
      }
    },
    async getPointsOnRanking() {
      if (this.establishment.configuracoes.exibir_pontuacao_acumulada_ranking) {
        const response = await this.MODULOS.points.getSingleRanking(this.user.id)
        this.singleRanking = response;
      }
    },

    getPointDateDescription(point) {
      if (this.pointIsTransfer(point)) return "Transferido em";
      switch (point.operacao) {
        case "C":
          return "Creditado em";
        case "D":
          return "Resgatado";
        case "E":
          return "Estornado em";
        default:
          return "-";
      }
    },

    pointIsTransfer(point) {
      return point.metadado_4 == "doacao";
    },

    pointIsCredit(point) {
      return point.operacao == 'C';
    },

    pointIsReversal(point) {
      return point.operacao == 'E';
    },

    formatPointDate(pointDate) {
      return pointDate.split('-').reverse().join('/');
    },

    formatPointAmount(pointAmount) {
      return pointAmount.toString().replace(/([-])/, '- ');
    },

    getPointBranch(pointBranch) {
      return pointBranch ? ` - filial: ${pointBranch}` : "";
    },
  },
}